/* src/index.css */
html, body, #root {
  height: 100%;
  margin: 0;
}

body {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #FFF3E0; /* Warm light background */
  font-family: 'Roboto', sans-serif; /* Material Design recommended font */
}

#root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background-color: #FFFFFF;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 100%; /* Ensure it doesn't overflow the screen */
}

@media (min-width: 600px) {
  .container {
    max-width: 400px; /* Set a max width for larger screens */
  }
}

.container-logged-in {
  width: 100%;
  height: 100%;
  padding-top: 0;
}

.greeting-container {
  width: 100%;
  text-align: center;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #FFFFFF;
}

.logout-button {
  position: absolute;
  top: 20px;
  right: 20px;
  transition: all 0.5s ease-in-out;
}

.login-form {
  transition: all 0.5s ease-in-out;
}

.container-logged-in .login-form {
  opacity: 0;
  transform: translateY(200px);
}
