/* src/pages/LogIn.css */
html, body, #root {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.login-page-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  position: relative;
  overflow: hidden;
}

.square {
  width: 300px;
  height: auto;
  padding: 20px;
  background-color: lightblue;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: all 0.5s ease-in-out;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.square.wide {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  transform: none;
}

.toggle-button,
.back-button,
.logout-button,
.register-button,
.password-reset-button,
.dialog-button {
  transition: all 0.5s ease-in-out;
  margin-top: 10px;
  background-color: white;
}

.toggle-button.wide,
.back-button.wide,
.logout-button.wide {
  position: absolute;
}

.toggle-button.wide {
  top: 20px;
  right: 20px;
}

.back-button.wide {
  top: 20px;
  left: 20px;
}

.logout-button.wide {
  top: 20px;
  right: 20px;
  margin-right: 20px;
}

.greeting {
  position: absolute;
  top: 20px;
  left: 20px;
  margin-left: 20px;
  transition: all 0.5s ease-in-out;
}
